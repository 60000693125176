import React from 'react';
import moment from 'moment';
import PageHeading from 'components/shared/PageHeading';

const LatestActivities = ({ limit, heading, timeStamps }) => {
    return (
        <>
            <PageHeading heading={heading ? heading : "Latest Activities"} />

            <ul className="activity-list">
                {timeStamps?.map((activity, index) => {
                    const formattedDate = moment(activity.createdAt).format('dddd, MMMM D YYYY');
                    return (
                        <li key={index} className="activity-item">
                            <div className="activity-circle"></div>
                            <div className="activity-content">
                                <p className='mb-0' style={{ fontSize: "16px", lineHeight: "30px", fontWeight: 400, color: "#A098AE" }}>
                                    {formattedDate}
                                </p>
                                <p style={{ fontSize: "18px", lineHeight: "30px", fontWeight: 400, color: "#303972" }}>
                                    <span dangerouslySetInnerHTML={{ __html: activity.note }} />
                                </p>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}

export default LatestActivities;
