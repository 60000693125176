import React, { useEffect, useState } from 'react'
import Statistics from '../Statistics/Statistics';
import { Button, Col, Image, Row, Table } from 'react-bootstrap';
import LatestActivities from 'views/Admin/user-management/LatestActivities';
import BreadCrumbs from 'components/shared/BreadCrumbs';
import SchoolCards from 'components/shared/SchoolCards';
import { getClassTeachersAndStudents } from 'services/Teacher/ClassesAndGroups.service';
import { useParams } from 'react-router-dom';
import PageHeading from 'components/shared/PageHeading';
import { getAllTimeStamps } from 'services/Teacher/TimeStamp.service';
import moment from 'moment';
import nodataimage from "../../../assets/nodataimage.svg"
import { getSpecificSubmissions } from 'services/Teacher/Assignments.service';
const ClassesAndGroupDetailPage = () => {
    const params = useParams()
    const [students, setStudents] = useState([])
    const [teachers, setTeachers] = useState([])
    const [loading, setLoading] = useState(false)
    const [exercises, setExercise] = useState([])

    const breadcrumbItems = [
        { label: 'Classes & Groups', href: '/classes&groups' },
        { label: "Classes & Groups", active: true }
    ];


    const [timeStamps, setTimeStamps] = useState([])


    const fetchTimeStamps = async () => {
        try {
            const response = await getAllTimeStamps(params.id, 'class');
            setTimeStamps(response.data.data || []);
            console.log("Time Stamps", response.data)
        } catch (error) {
            console.error('Error fetching teachers:', error);
        }
    };


    const fetchUsers = async () => {
        const userType = "student"
        setLoading(true);
        try {
            const response = await getClassTeachersAndStudents(params.id, userType);
            setStudents(response.data.results.students || []);
        } catch (error) {
            console.error("Error fetching users:", error);
            setStudents([]);
        } finally {
            setLoading(false);
        }
    };
    const fetchTeachers = async () => {
        const userType = "teacher"
        setLoading(true);
        try {
            const response = await getClassTeachersAndStudents(params.id, userType);
            setTeachers(response.data.results.teachers || []);
            console.log("response.data", response.data.results)
        } catch (error) {
            console.error("Error fetching users:", error);
            setTeachers([]);
        } finally {
            setLoading(false);
        }
    };
    const fetchAssignments = async () => {
        const type = "class"
        setLoading(true);
        try {
            const response = await getSpecificSubmissions(params.id, type);
            setExercise(response.data.results || []);
            console.log("assignments", response.data)
        } catch (error) {
            console.error("Error fetching users:", error);
            setTeachers([]);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchUsers();
        fetchTeachers();
        fetchTimeStamps();
        fetchAssignments()
    }, []);


    const getLevelBackgroundColor = (level) => {
        switch (level) {
            case 'Elementary':
                return '#C4C4C4';
            case 'Intermediate':
                return '#4DA9F4';
            case 'Beginner':
                return '#0FE133';
            case 'Advanced':
                return '#EB3232';
            case 'Expert':
                return '#408c40';
            default:
                return '#B0BEC5';
        }
    };


    return (
        <>


            <BreadCrumbs items={breadcrumbItems} />
            <Statistics />
            <div>
                <Row>
                    <Col xs={12}>
                        <LatestActivities timeStamps={timeStamps} />
                    </Col>

                </Row>
            </div>


            <div>
                <PageHeading heading={"Students"} />
                <Row>


                    {students?.map((students, index) => (
                        <Col xs={12} lg={3} key={students._id || index}>
                            <SchoolCards
                                id={students._id}
                                isActive={true}
                            showToggle={false}
                            Name={students.fullName}
                                designation={"Student"}
                                image={students.imageUrl}
                                number={students.number}
                                email={students.email}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
            <div>
                <PageHeading heading={"Teachers"} />
                <Row>
                    {teachers?.map((students, index) => (
                        <Col xs={12} lg={3} key={students._id || index}>
                            <SchoolCards
                                id={students._id}
                                Name={students.fullName}
                                designation={"Student"}
                                isActive={true}
                            showToggle={false}
                            image={students.imageUrl}
                                number={students.number}
                                email={students.email}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
            <div>
                <div className="mt-4">
                    <Table responsive="sm" style={{ overflow: "auto" }}>
                        <thead>
                            <tr>
                                <th style={{ color: "#303972" }}>Assignment Name</th>
                                <th style={{ color: "#303972" }}>Subject</th>
                                <th style={{ color: "#303972" }}>Deadline</th>
                                <th style={{ color: "#303972" }}>Status</th>
                                <th style={{ color: "#303972" }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                [...Array(4)].map((_, index) => (
                                    <tr key={index}>
                                        <td className="py-3">
                                            <div
                                                style={{
                                                    backgroundColor: "#e0e0e0",
                                                    height: "20px",
                                                    width: "80%",
                                                    borderRadius: "4px",
                                                }}
                                            ></div>
                                        </td>
                                        <td className="py-3">
                                            <div
                                                style={{
                                                    backgroundColor: "#e0e0e0",
                                                    height: "20px",
                                                    width: "40%",
                                                    borderRadius: "4px",
                                                }}
                                            ></div>
                                        </td>
                                        <td className="py-3">
                                            <div
                                                style={{
                                                    backgroundColor: "#e0e0e0",
                                                    height: "20px",
                                                    width: "40%",
                                                    borderRadius: "4px",
                                                }}
                                            ></div>
                                        </td>
                                        <td className="py-3">
                                            <div
                                                style={{
                                                    backgroundColor: "#e0e0e0",
                                                    height: "20px",
                                                    width: "40%",
                                                    borderRadius: "4px",
                                                }}
                                            ></div>
                                        </td>
                                        <td className="py-3">
                                            <div
                                                style={{
                                                    backgroundColor: "#e0e0e0",
                                                    height: "20px",
                                                    width: "40%",
                                                    borderRadius: "4px",
                                                }}
                                            ></div>
                                        </td>
                                    </tr>
                                ))
                            ) : exercises.length === 0 ? (
                                <tr>
                                    <td colSpan="5" className="text-center py-3">
                                        <Image src={nodataimage} />
                                    </td>
                                </tr>
                            ) : (
                                exercises.map((exercise, index) => (
                                    <tr key={exercise._id}>
                                        <td className="py-3">
                                            <div
                                                //   to={`attempt/${exercise?.exercise?._id}`}
                                                style={{
                                                    color: "#303972",
                                                    fontWeight: 700,
                                                    textDecoration: "none",
                                                }}
                                            >
                                                Exercise {index + 1} : {exercise.title}
                                            </div>
                                        </td>
                                        <td className="py-3" style={{ color: "#A098AE" }}>
                                            {exercise?.exercise?.subject?.title}
                                        </td>
                                        <td className="py-3" style={{ color: "#A098AE" }}>
                                            {moment(exercise?.endDate).format("MMM D, YYYY")}
                                        </td>
                                        <td className="py-3" style={{ color: "#A098AE" }}>
                                            <span
                                                style={{
                                                    display: "inline-block",
                                                    padding: "3px 10px",
                                                    borderRadius: "30px",
                                                    backgroundColor: getLevelBackgroundColor(
                                                        exercise?.submissionStatus
                                                    ),
                                                    color: "#fff",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                {exercise?.submissionStatus?.toUpperCase()}
                                            </span>
                                        </td>
                                        <td className="py-3">
                                            <Button
                                                variant="primary"
                                                className="button-style border-0 py-1 px-3"
                                                style={{
                                                    fontSize: "14px"
                                                }}

                                            >
                                                click
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>

        </>
    )
}

export default ClassesAndGroupDetailPage