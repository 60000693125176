import PageHeading from 'components/shared/PageHeading';
import Select from 'components/shared/Select';
import React, { useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: '#4D44B5',
                padding: '10px 30px',
                borderRadius: '8px',
                color: '#fff',
                border: '1px solid #444',
                textAlign: "center"
            }}>
                <p className='m-0'>{`${label}`}</p>
                <p className='m-0' style={{ color: "#FB7D5B", fontWeight: "700" }}>{`UV: ${payload[0].value}%`}</p>
                <p className='m-0' style={{ color: "#FCC43E", fontWeight: "700" }}>{`PV: ${payload[1].value}%`}</p>
            </div>
        );
    }

    return null;
};

const Statistics = () => {
    const [language, setLanguage] = useState("");
    const [subjects, setSubject] = useState("");

    const data = [
        { name: 'January', uv: 40, pv: 24 },
        { name: 'February', uv: 30, pv: 13.98 },
        { name: 'March', uv: 20, pv: 98 },
        { name: 'April', uv: 27.8, pv: 39.08 },
        { name: 'May', uv: 18.9, pv: 48 },
        { name: 'June', uv: 23.9, pv: 38 },
        { name: 'July', uv: 34.9, pv: 43 },
    ];

    const options = [
        { value: '', label: 'Select Class' },
        { value: '1', label: 'Class 1' },
    ];
    const subject = [
        { value: '', label: 'Select Subject' },
        { value: '1', label: 'English' },
    ];

    return (
        <>
            <div className="d-flex justify-content-between">
                <div>
                    <PageHeading heading={"Statistics"} />
                </div>
                <div className='d-flex gap-2'>
                    <Select
                        value={language}
                        onChange={setLanguage}
                        options={options}
                        ariaLabel="Select Language"
                        required
                    />
                    <Select
                        value={subjects}
                        onChange={setSubject}
                        options={subject}
                        ariaLabel="Select Language"
                        required
                    />
                </div>
            </div>
            <ResponsiveContainer className={"my-3"} width="100%" height={400}>
                <AreaChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 30,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#FB7D5B" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#FB7D5B" stopOpacity={0} />
                        </linearGradient>
                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#FCC43E" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#FCC43E" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={(tick) => `${tick}%`} />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="monotone"
                        dataKey="uv"
                        stackId="1"
                        stroke="#FB7D5B"
                        fill="url(#colorUv)"
                        strokeWidth={6}
                    />
                    <Area
                        type="monotone"
                        dataKey="pv"
                        stackId="1"
                        stroke="#FCC43E"
                        fill="url(#colorPv)"
                        strokeWidth={6}
                    />
                    <Legend verticalAlign="bottom" align="center" />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
};

export default Statistics;
