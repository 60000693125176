import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import PageHeading from 'components/shared/PageHeading';
import Groups from '../Dashboard/Groups';
import AddClassModal from 'components/modals/AddClassModal';
import { getAllClasses } from 'services/Teacher/Class.service';
import { useNavigate } from 'react-router-dom';
const ClassesAndGroups = () => {
    const [showModal, setShowModal] = useState(false);
    const [classes, setClasses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate =useNavigate()
    const handleCloseModal = () => {
        setShowModal(false)
    }

    const fetchClasses = async () => {
        setIsLoading(true)
        try {
            const response = await getAllClasses();
            setClasses(response?.data?.results?.classes || []);
            setIsLoading(false)
            console.log('clases:', response.data);
        } catch (error) {
            setIsLoading(false)
            console.error('Error fetching teachers:', error);
        }
    };
    useEffect(() => {
        fetchClasses();
    }, []);

    return (
        <>
            <PageHeading heading={"Classes & Groups"} />
            <div>
                <div className="d-flex justify-content-between">
                    <div>
                        <PageHeading heading={"Classes"} />
                    </div>
                    <div>
                        <Button className='button-style border-0 py-2 px-3' onClick={() => setShowModal(true)}>Add Classes</Button>
                    </div>
                </div>
                {classes?.map((classItem) => (
                    <div onClick={() => navigate(classItem?._id)} key={classItem?._id} className='text-decoration-none' style={{cursor:"pointer"}}>
                        <div className='py-0 custom-item text-wrap d-flex justify-content-between border-bottom mb-2'>
                            <div className='d-flex gap-3 my-3'>
                                <div
                                    style={{
                                        width: '44px',
                                        height: '44px',
                                        borderRadius: '60px',
                                        background: '#000000'
                                    }}
                                ></div>
                                <div>
                                    <div>
                                        <h6
                                            className='m-0'
                                            style={{ color: '#303972', fontWeight: '700' }}
                                        >
                                            {classItem?.title}
                                        </h6>
                                    </div>
                                    <small className='text-muted text-decoration-none'>
                                        {classItem?.subject.title || ''}
                                    </small>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
            </div>


            <AddClassModal
                show={showModal}
                handleClose={handleCloseModal}
                fetchClasses={fetchClasses}
            />
        </>
    );
};

export default ClassesAndGroups;
